import React from 'react';
import {client} from '../api';

class Sandbox extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            serverResponse: ''
        }
    }

    componentDidMount() {
        client.get("/test").then(response => this.setState({serverResponse: response.data}));


        let socket = new WebSocket('ws://localhost:8080/my-websocket-endpoint');

// Add an event listener for when a connection is open
        socket.onopen = function() {
            console.log('WebSocket connection opened. Ready to send messages.');

            // Send a message to the server
            socket.send('Hello, from WebSocket client!');
        };

// Add an event listener for when a message is received from the server
        socket.onmessage = function(message) {
            console.log('Message received from server: ' + message);
        };
    }

    render() {
        return (
            <div>
                <h1>Calling Server:</h1>
                <h2>{this.state.serverResponse}</h2>
            </div>
        );
    }
}

export default Sandbox;
