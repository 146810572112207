import axios from 'axios';

let backendHost;

const invokeUrl = 'https://jjz0chtqib.execute-api.us-east-1.amazonaws.com/dev';

const hostname = window && window.location && window.location.hostname;
if(hostname === 'cecere-sandbox.com') {
    //backendHost = 'http://35.168.32.62:8080';
    backendHost = invokeUrl;
} else {
    backendHost = 'http://localhost:8080';
   // backendHost = invokeUrl;
}

export const client = axios.create({
    baseURL: backendHost,
    headers: {
       // 'Access-Control-Allow-Origin': backendHost
    }
});
