import React from 'react';
import logo from '../logo.svg';

const Home = () => {

    return (

        <div>
            <img src={logo} className="App-logo" alt="logo" />
            <h2>Sandbox App for Testing</h2>
        </div>
    );
};

export default Home;