import React, {useState} from 'react';
import {GoogleLogin} from 'react-google-login';
import '../App.css'
import Avatar from '@material-ui/core/Avatar';
import {withStyles} from '@material-ui/core/styles';

const styles = {
    avatar: {
        margin: 10,
    }
};

const SignIn = props => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const {classes} = props;

    const onSignIn = googleUser => {
        const profile = googleUser.getBasicProfile();
        console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.              
        setIsLoggedIn(true);
        setImageUrl(profile.getImageUrl());
    };

    const onFail = () => {
        console.log("failed");
    };


    return (
        <div>
            {!isLoggedIn &&
            <GoogleLogin
                clientId="147307084240-5ad4di3vbbmhb37gds5dcl5d3l8fn82m.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={onSignIn}
                onFailure={onFail}
                className="Login"/>
            }
            {isLoggedIn &&
            <Avatar alt="Profile" src={imageUrl} className={classes.avatar} component="div"/>
            }
        </div>
    );
};

export default withStyles(styles)(SignIn);