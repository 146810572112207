import React from 'react';

import {Responsive, WidthProvider} from 'react-grid-layout';

import {blue, red, orange} from '@material-ui/core/colors';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class GridTest extends React.Component {
   static maximize() {
        console.log('clicked');
    }

    render() {
        // const layouts = [
        //     {i: 'a', x: 0, y: 0, w: 1, h: 2, static: true},
        //     {i: 'b', x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4},
        //     {i: 'c', x: 4, y: 0, w: 1, h: 2}
        // ];

        return (
            <>
                <ResponsiveReactGridLayout className="layout"
                                           ref={rgl => this.rgl = rgl}
                                           breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                                           cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
                    <div key="1" style={{backgroundColor: blue.A200}}>
                        <form>
                             <input type="test" name="test" defaultValue="test" style={{width: '100px'}}/>
                        </form>
                    </div>
                    <div key="2" style={{backgroundColor: red.A200}}>
                        <button onClick={GridTest.maximize}>Maximize</button>
                    </div>
                    <div key="3" style={{backgroundColor: orange.A200}}>3</div>
                </ResponsiveReactGridLayout>
            </>
        )
    }
}

export default GridTest;