import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const Scheduler = () => {

    const useStyles = makeStyles(theme => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column'
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '54%',
            color: '#00000'

        },
        dense: {
            marginTop: theme.spacing(2),
        },
        menu: {
            width: 200,
        },
    }));

    const [values, setValues] = React.useState({
        age: '',
        interests: [],
        locations: [],
        dates: []
    });

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const classes = useStyles();

    return (
        <form className={classes.container} autoComplete={false}>
            <h1>Schedule Builder</h1>
            <div>Age</div>
            <div>Interests</div>
            <div>Locations</div>
            <div>Dates</div>
            <TextField
                label="Age"
                className={classes.textField}
                value={values.age}
                onChange={handleChange('age')}
                placeholder="Age"
                margin="normal"
                variant="outlined"
            />

        </form>
    );
};

export default Scheduler;